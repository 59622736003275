body{
  font-size: 14px;
  line-height: 20px;
}
.table{
  font-size: 14px;
  th{
    white-space: nowrap;
    padding: 10px;
  }
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.question-filter-wrap{
  .page-heading{
    font-size: 24px;
    margin-bottom: 15px;
  }
  .left-wrap{
    width: 100%;
    .floating-input-wrap{
      width: 100%;
    }
  }
}

.form-wrap{
	// max-width: 1000px;
	max-width: 90%;
	margin: 2% auto;
	.page-heading{
		font-size: 24px;
		margin-bottom: 15px;
	}
	.left-wrap{
		width: 100%;
    padding: 0px 10px 0px 10px;
		.floating-input-wrap{
			width: 100%;
		}
	}
	.option-action{
		align-self: center;
		.btn{
    		margin-bottom: 10px;
		}
	}
}

.selection-box-wrap{
	background-color: #f3f3f3;
  padding:  15px;
  border-radius: 12px;
}
.page-content{
  padding-left: 300px;
}
.toggled .ion-page{
  padding-left: 260px;
}
.read-only{
.ql-toolbar{
  display:none;
}
.ql-container.ql-snow{
  border: none;
}
}